<template>
  <div>
    <!-- Redeem Data Table with Filter -->
    <div>
      <el-form>
        <el-form-item>
          <el-input
            v-model="redeemSearch"
            placeholder="Search in redeem data..."
          />
        </el-form-item>
      </el-form>
      <el-table :data="filteredRedeemData" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column prop="order_id" label="Order Id" min-width="100"></el-table-column>
        <el-table-column prop="product_name" label="Product Name" min-width="80" sortable></el-table-column>
        <el-table-column prop="amount_points" label="Redeem Amount" min-width="80" sortable></el-table-column>
        <el-table-column prop="transactionTime" label="Transaction Time" min-width="80" sortable></el-table-column>
        <el-table-column prop="status_text" label="Status" min-width="80" sortable></el-table-column>
        <el-table-column prop="redeem_url" label="Redeem URL" min-width="80" sortable></el-table-column>
        <el-table-column prop="currencyType" label="Currency Type" min-width="45"></el-table-column>
      </el-table>
    </div>
  </div>

  <!-- dialog boxes -->
  <div>
    <!-- error dialog -->
    <el-dialog v-model="dialogErrorVisible" title="Error" @close="dialogErrorVisible = false" width="50%">
      <div style="text-align: left;">
        <p>Error: {{ errorMessage }}</p>
      </div>
      <template v-slot:footer>
        <el-button type="primary" @click="dialogErrorVisible = false">OK</el-button>
      </template>
    </el-dialog>
    </div>
</template>

<style>
  .el-table .warning-row {
  color: red !important;
} 
</style>

<script>
import { ref, computed } from "vue";
import { watch } from 'vue';
import { addMissingRewards } from '@/lib/dataServices';

export default {
  props: {
    redeemData: Array
  },
  setup(props, context) {
    // make redeemData reactive
    const redeemData = ref(props.redeemData);
      watch(() => props.redeemData, (newVal, oldVal) => {
        redeemData.value = newVal;
      });

    const redeemSearch = ref("");
    const errorMessage = ref("");

    const showErrorDialog = function (error) {
        errorMessage.value = error.message;
        dialogErrorVisible.value = true;
    };

    return {
      redeemData,
      filteredReDeemData,
      dialogErrorVisible,
      errorMessage,
    };
  }
};
</script>

