import { createApp } from 'vue'
import App from './App.vue'
//import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(store) //.use(router)
app.use(ElementPlus)
app.mount('#app')

//app.config.globalProperties.$ELEMENT = { size: 'small', zIndex: 3000 };
