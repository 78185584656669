<template>
  <div>
    <!-- Add Missing Daily Rewards Box flex-end -->
    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px">
      <div style="width: 40%">
        <el-table :data="panelistData" style="width: 60%">
          <el-table-column prop="missingDays" label="Missing Days" align="left"></el-table-column>
          <el-table-column prop="missingRewards" label="Missing Rewards" align="left"></el-table-column>
        </el-table>
      </div>
      <el-form inline style="display: flex; align-items: center;">
        <el-form-item>
          <el-input label="Zendesk ticket no." v-model="z_ticket_no" placeholder="Zendesk ticket number" :maxlength="32"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="confirmAddMissingRewards" type="warning" :disabled="isAddRewardsButtonDisabled"
            >Add Missing Rewards
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- Rewards Data Table with Filter -->
    <div>
      <el-form>
        <el-form-item>
          <el-input
            v-model="rewardSearch"
            placeholder="Search in reward data..."
          />
        </el-form-item>
      </el-form>
      <el-table 
        :data="filteredRewardData" 
        ref="tableRef"
        style="width: 100%" 
        :row-class-name="tableRowClassName"
        reserve-selection
        row-key="id"
        @selection-change="onRowSelectChange"
      >
        <!-- Checkbox Column -->
        <el-table-column 
          type="selection"  
          :selectable="isSelectable"  
          width="55"
        >
        </el-table-column>

        <!-- data Columns -->
        <el-table-column prop="panelistId" label="PanelistId" min-width="100"></el-table-column>
        <el-table-column prop="transactionTime" label="Transaction Time" min-width="80" sortable></el-table-column>
        <el-table-column prop="transactionType" label="Transaction Type" min-width="80" sortable></el-table-column>
        <el-table-column prop="rewardAmount" label="Reward Amount" min-width="80" sortable></el-table-column>
        <el-table-column prop="rewardReason" label="Reward Reason" min-width="80" sortable></el-table-column>
        <el-table-column prop="rewardAdditionalInfo" label="Additional Info" min-width="80" sortable></el-table-column>
        <el-table-column prop="currencyType" label="Currency Type" min-width="45"></el-table-column>
      </el-table>
    </div>
  </div>

  <!-- dialog boxes -->
  <!-- 
  <p>Number of Missing Days: {{ panelistData[0].missingDays }}</p>
        <p>Total Missing Daily Rewards: {{ panelistData[0].missingRewards }}</p>
        missingRewardTransListToAdd.value.length 
  -->
  <div>
    <!-- add missing rewards confirmation dialog -->
    <el-dialog v-model="dialogVisible" :before-close="handleClose" title="Confirmation">
      <div>
        <p>Zendesk Ticket Number: {{ z_ticket_no }}</p>
        <p>Number of Missing Days to Add: {{ numberOfMissingRewardsToAdd  }}</p>
        <p>Total Missing Daily Rewards to Add: {{ numberOfMissingRewardsToAdd * 160 }}</p>
      </div>
      <template v-slot:footer>
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="executeAddMissingRewards">OK</el-button>
      </template>
    </el-dialog>
    <!-- error dialog -->
    <el-dialog v-model="dialogErrorVisible" title="Error" @close="dialogErrorVisible = false" width="50%">
      <div style="text-align: left;">
        <p>Error: {{ errorMessage }}</p>
      </div>
      <template v-slot:footer>
        <el-button type="primary" @click="dialogErrorVisible = false">OK</el-button>
      </template>
    </el-dialog>
    </div>
</template>

<style>
  .el-table .warning-row {
  color: red !important;
} 
</style>

<script>
import { ref, computed } from "vue";
import { watch } from 'vue';
import { addMissingRewards } from '@/lib/dataServices';

import { ElTable } from 'element-plus';

export default {
  props: {
    loginUserEmail: String,
    panelistData: Array,
    rewardData: Array,
    missingRewardTransList: Array
  },
  emits: ['rewardsAddedEvent'],
  setup(props, context) {
    // make props.rewardData from  reactive

    // make panelistData reactive
    const panelistData = ref(props.panelistData);
    watch(() => props.panelistData, (newVal, oldVal) => {
      panelistData.value = newVal;
      z_ticket_no.value = "";
    });

    // make rewardData reactive
    const rewardData = ref(props.rewardData);
      watch(() => props.rewardData, (newVal, oldVal) => {
        rewardData.value = newVal;
      });

    // make missingRewardTransList reactive
    const missingRewardTransList = ref(props.missingRewardTransList);
      watch(() => props.missingRewardTransList, (newVal, oldVal) => {
        missingRewardTransList.value = newVal;
      });

    // missingRewardTransListToAdd
    const missingRewardTransListToAdd = ref(null); 
    const numberOfMissingRewardsToAdd = ref(0);

    const tableRef = ref(null);

    const rewardSearch = ref("");
    const z_ticket_no = ref("");
    const dialogVisible = ref(false);
    const dialogErrorVisible = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref("");
    const progress = ref(0);

    console.log("loginUserEmail: " + props.loginUserEmail);

    const progressColorFunction = (percentage) => {
      if (percentage <= 10) {
        return 'yellow';
      } else if (percentage <= 40) {
        return 'orange';
      } else {
        return 'green';
      }
    };

    var createdTime = 0;

    const tableRowClassName = ({ row, rowIndex }) => {
      // check if row is null or undefined  and return empty string if row  is null or undefined
      if (!row || !row.rewardAdditionalInfo) {
        //console.log("row or row.rewardAdditionalInfo is null or undefined");
        //console.log("rowIndex: ", rowIndex);
        return '';
      }

      //console.log('row:', row);
      if (row && row.rewardAdditionalInfo.includes('missing rewards')) {
        return 'warning-row';
      }
      
      return '';
    };

    const handleClose = done => {
        dialogVisible.value = false;
    };

    const confirmAddMissingRewards = function () {
        dialogVisible.value = true;
    };

    const showErrorDialog = function (error) {
        errorMessage.value = error.message;
        dialogErrorVisible.value = true;
    };

    const checkResult = function (result) {
      // check the response status code to see if the API call was successful or not 
      if (result.cumulativeStatus.responseStatusCode !== "SURVEY_HANDLER_STATUS_SUCCESS") {
        // create an error object with the error message then throw it
        throw new Error("backend error: " + result.cumulativeStatus.responseStatusText);
      }
    }  

    const executeAddMissingRewards = async () => {
      try {
        //console.log("aaab1:missingRewardTransList.value: ", missingRewardTransList.value);
        //console.log("aaab2:missingRewardTransListToAdd.value: ", missingRewardTransListToAdd.value);
        const result = await addMissingRewards(missingRewardTransListToAdd.value, z_ticket_no.value, props.loginUserEmail);
        
        dialogVisible.value = false;

        context.emit('rewardsAddedEvent', "missing rewards added successfully");

      } catch (error) {
        console.log("error: ", error);
        showErrorDialog(error);
      }
    };  

    const isAddRewardsButtonDisabled = computed(() => {
      return !z_ticket_no.value || !z_ticket_no.value.trim() || panelistData.value[0].panelistGroupName != 'default' || missingRewardTransList.value.length == 0;
    });

    const filteredRewardData = computed(() => {
      if (rewardSearch.value) {
        // Split the search string into an array of words and trim white spaces
        const words = rewardSearch.value.split(',').map(word => word.trim());

        return rewardData.value.filter((row) =>
          // Check if any word is included in any property of the row
          words.every((word) =>
            Object.values(row).some((value) =>
              String(value)
                .toLowerCase()
                .includes(word.toLowerCase())
            )
          )
        );
      } else {
        return rewardData.value;
      }
    });
    
    const isSelectable = (row, index) => {
      //console.log("isSelectable:row, index", row, index);
      return row.rewardAdditionalInfo === 'missing rewards';
    };

    const onRowSelectChange = (selection) => {
      missingRewardTransListToAdd.value = [];
      selection.forEach(selectedRow => {
        var rowData = {};

        // loop through the row object and add the properties to the rowData object
        Object.keys(selectedRow).forEach(key => {
          rowData[key] = selectedRow[key];
        });
        const newDate = new Date(rowData.transactionTime);
        rowData.transactionTime = newDate.getTime();
        console.log('aaa1:', rowData);
        missingRewardTransListToAdd.value.push(rowData);
      });

      numberOfMissingRewardsToAdd.value = selection.length;
     };

    return {
      panelistData,
      rewardData,
      rewardSearch,
      tableRowClassName,
      filteredRewardData,
      z_ticket_no,
      isAddRewardsButtonDisabled,
      dialogVisible,
      dialogErrorVisible,
      handleClose,
      confirmAddMissingRewards,
      executeAddMissingRewards,
      isLoading,
      errorMessage,
      progress,
      progressColorFunction,
      onRowSelectChange,
      isSelectable,
      numberOfMissingRewardsToAdd
    };
  }
};
</script>

