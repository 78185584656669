const awsmobile_uk = {
   /* gws eu-west-2 */
   "aws_project_region": "eu-west-2",
   "aws_cognito_region": "eu-west-2",
   "aws_cognito_identity_pool_id": "eu-west-2:9f7d2cf9-03e4-44fb-80a2-e7b62d6400ec",
   "aws_user_pools_id": "eu-west-2_E5BmIl137",
   "aws_user_pools_web_client_id": "2g46obt4pfceo0n0vmuu9ufo3d",
   "aws_cloud_logic_custom": [
    {
      name: "omp-webapp-services-uk-prod",
      endpoint:
        "https://6zoi4vxmu8.execute-api.eu-west-2.amazonaws.com/prod",
    },          
    {
      name: "omp-webapp-services-uk-staging",
      endpoint:
        "https://ibcuchx1t4.execute-api.eu-west-2.amazonaws.com/staging",
    },
    {
      name: "MyAPIGatewayAPI",
      endpoint:
        "https://45mrqa7hu5.execute-api.us-east-1.amazonaws.com/staging/panel",
    },
],
};

const awsmobile_us = {   
   /* gws us-east-1 */
   "aws_project_region": "us-east-1",
   "aws_cognito_region": "us-east-1",
   "aws_cognito_identity_pool_id": "us-east-1:4cccdf6b-1df8-4227-9f66-637bca406e78",
   "aws_user_pools_id": "us-east-1_RjOvZl7c4",
   "aws_user_pools_web_client_id": "24odf163td9jrbaa7pjcqcsam",
   /*
   "aws_cognito_region": "eu-west-2",
   "aws_cognito_identity_pool_id": "eu-west-2:9f7d2cf9-03e4-44fb-80a2-e7b62d6400ec",
   "aws_user_pools_id": "eu-west-2_E5BmIl137",
   "aws_user_pools_web_client_id": "2g46obt4pfceo0n0vmuu9ufo3d",*/

   "aws_cloud_logic_custom": [
    {
      name: "omp-webapp-services-uk-prod",
      endpoint:
        "https://733uvb6w1j.execute-api.us-east-1.amazonaws.com/prod",
    }
],
};

export default {uk: awsmobile_uk, us: awsmobile_us};
