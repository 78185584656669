<template>
  <div>
    <el-table :data="panelistData" style="width: 100%">
      <el-table-column prop="email" label="Email" min-width="120"></el-table-column>
      <el-table-column prop="panelistId" label="PanelistId" min-width="150"></el-table-column>
      <el-table-column prop="panelistGroupName" label="Panelist Group Name" min-width="100">
        <template #default="{ row }">
          <div :style="{ color: row.panelistGroupName !== 'default' ? 'red' : '', fontWeight: row.panelistGroupName !== 'default' ? 'bold' : '' }">{{ row.panelistGroupName }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="lifetimeRewardAmount" label="Lifetime Amount" min-width="120"></el-table-column>
      <el-table-column prop="walletRewardAmount" label="Wallet Amount" min-width="120"></el-table-column>
      <el-table-column prop="createdTime" label="Created Time" min-width="180"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from "vue";
import { watch } from 'vue';

export default {
  props: {
    panelistData: Array
  },
  setup(props) {
    // make panelistData reactive
    const panelistData = ref(props.panelistData);
    watch(() => props.panelistData, (newVal, oldVal) => {
      panelistData.value = newVal;
    });
    return {
      panelistData
    };
  }
};
</script>

