import { callAmplifyAPI } from './backendServices';

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const formattedDateTime = date
    .toISOString()
    .replace("T", " ")
    .slice(0, -5);
  return formattedDateTime;
}

const checkResult = function (result) {
  // check the response status code to see if the API call was successful or not 
  if (result.cumulativeStatus.responseStatusCode !== "SURVEY_HANDLER_STATUS_SUCCESS") {
    // create an error object with the error message then throw it
    throw new Error("backend error: " + result.cumulativeStatus.responseStatusText);
  }
}  

// return panelist data in an array
export async function fetchPanelistData(email) {
  try {
    const panelistData = [];
    var createdTime = 0;

    const request = {
        meterVersion: 999,
        requestOriginator: "webapp",
        panelistInfoAction: "get_panelist_info_by_email_spec_in_pan_rec",
        surveyPanelistInfoTblPts: {
          email: email,
        }
      }

    const result = await callAmplifyAPI(request, "/panelist");

    if (result.surveyPanelistInfoMeterQueryDataPts.surveyIntfcPanelistInfoMeterRecord &&
        result.surveyPanelistInfoMeterQueryDataPts.surveyIntfcPanelistInfoMeterRecord.length > 0 ) {

      var numOfPanelistIds = result.surveyPanelistInfoMeterQueryDataPts.surveyIntfcPanelistInfoMeterRecord.length;
      for(var i = 0; i < numOfPanelistIds; i++) {
        var data = result.surveyPanelistInfoMeterQueryDataPts.surveyIntfcPanelistInfoMeterRecord[0].surveyPanelistInfoTbl;

        createdTime = data.createdTime;
        data.createdTime = convertTimestamp(data.createdTime);
        // TODO: add currency field to data for empty rewardTran processing later

        panelistData.push(data);
      }
    } else {
      console.log( "Error: surveyIntfcPanelistInfoMeterRecord is undefined or empty");
      throw new Error("Email not found");
    }

    return {panelistData:panelistData, createdTime:createdTime};

  } catch (error) {
    console.log("Error calling API in fetchPanelistData()", error);
    throw(error);
  } 
}

// TODO: handle the case when rewardTransList is empty. need to pass in a rewardTran template
function getMissingRewards(rewardTransList, createdTime) {
  // Filter the transactions that have a rewardAmount of 160 and a transactionType of 'earned'
  console.log("createdTime:", createdTime);
  var relevantTransList = rewardTransList.filter(
    (item) =>
      item.rewardReason === "daily use" && item.transactionType === "earned"
  );

  // Get the latest transactionTime of the item whose rewardAdditionalInfo meets the specific conditions
  const latestTransItem = relevantTransList
    .filter(
      (item) =>
        (item.rewardAdditionalInfo.includes("#") 
          && !item.rewardAdditionalInfo.includes("gws") 
          && !item.rewardAdditionalInfo.includes("development@onemeasureperks.com")
        )||
        (item.rewardAdditionalInfo.includes("OM") &&
          !item.rewardAdditionalInfo.includes("Friend"))
    )
    .sort((a, b) => b.transactionTime - a.transactionTime)[0];

  console.log("latestTransItem:", latestTransItem);

  const startTime = Math.max(
    createdTime,
    latestTransItem?.transactionTime ?? 0
  );
  
  //console.log("startTime:", startTime);

  const endTime = new Date().getTime() // + 86400000; ignore the current day // Get the current system time
  //console.log("endTime:", endTime);

  const missingRewardTransList_t = [];

  // Append the start and endTime as pseudo transactions to relevantTransList
  const tempItem = rewardTransList[0];
  relevantTransList.push({
    panelistId: tempItem.panelistId,
    deviceId: tempItem.deviceId,
    email: tempItem.email,
    currencyType: tempItem.currencyType,
    transactionTime: startTime,
    rewardAmount: 0,
    transactionType: "pending",
    rewardReason: "start of period",
    rewardAdditionalInfo: "system-generated",
  });

  relevantTransList.push({
    panelistId: tempItem.panelistId,
    deviceId: tempItem.deviceId,
    email: tempItem.email,
    currencyType: tempItem.currencyType,
    transactionTime: endTime,
    rewardAmount: 0,
    transactionType: "pending",
    rewardReason: "end of period",
    rewardAdditionalInfo: "system-generated",
  });

  // Sort the transactions by transactionTime in ascending order
  relevantTransList = relevantTransList.sort(
    (a, b) => a.transactionTime - b.transactionTime
  );
  //console.log("relevantTransList:", relevantTransList);

  for (let i = 1; i < relevantTransList.length; i++) {
    const prevDate = new Date(
      Math.max(relevantTransList[i - 1].transactionTime, startTime)
    );
    const currDate = new Date(relevantTransList[i].transactionTime);

    // Normalize the transaction times to the start of the day
    prevDate.setUTCHours(0, 0, 0, 0);
    currDate.setUTCHours(0, 0, 0, 0); //setUTCHours
    //console.log("prevDate:", prevDate.toUTCString());
    //console.log("currDate:", currDate.toUTCString());

    // Calculate the number of missing days
    const missingDays = Math.floor((currDate - prevDate) / 86400000) - 1; // 86400000 milliseconds = 1 day

    //console.log("missingDays:", missingDays);

    // Create a new transaction for each missing day
    for (let j = 0; j < missingDays; j++) {
      const missingTrans = { ...relevantTransList[i - 1] };
      //console.log(`currDate=${currDate.toUTCString()}, prevDate=${prevDate.toUTCString()}, missingDays=${missingDays}`)
      //console.log(`j=${j}, missingTrans:${JSON.stringify(missingTrans, null, 2)}`)
      //console.log("transaction time:", new Date(missingTrans.transactionTime).toUTCString());
      missingTrans.transactionTime =
        prevDate.getTime() + 86400000 * (j + 1); // Add the missing days to the previous date
      
      //console.log("transaction time:", new Date(missingTrans.transactionTime).toUTCString());
  
      missingTrans.rewardAmount = 160;
      missingTrans.transactionType = "earned";
      missingTrans.rewardReason = "daily use";
      missingTrans.rewardAdditionalInfo = "missing rewards";

      missingRewardTransList_t.push(missingTrans);
    }
  }

  //console.log("missingRewardTransList:", missingRewardTransList);

  return missingRewardTransList_t;
}

export async function fetchRewardData(panelistId, deviceId, createdTime) {
  try {
    const request = {
        meterVersion: 999,
        panelistInfoAction: "GET_REWARDS_BY_PANELIST_ID",
        requestOriginator: "webapp",
        panelistId: panelistId,
        deviceId: deviceId,
      }
    const result = await callAmplifyAPI(request, "/rewards");

    checkResult(result);

    // Get the reward transactions
    var rewardTransList = result.surveyRewardTransMeterQueryDataPts.rewardTransList;

    var missingRewardTransList = getMissingRewards(
      rewardTransList,
      createdTime
    );

    //console.log("fetchUserData:missingRewardTransList:", missingRewardTransList);

    // Add the missing transactions to the original list
    rewardTransList.push(...missingRewardTransList);

    // Convert transactionTime for each element in the array
    var convertedRewardTransList = rewardTransList
      .map((item) => {
        return {
          email: item.email,
          panelistId: item.panelistId,
          deviceId: item.deviceId,
          transactionTime: convertTimestamp(item.transactionTime),
          transactionType: item.transactionType,
          currencyType: item.currencyType,
          rewardAmount: item.rewardAmount,
          rewardReason: item.rewardReason,
          rewardAdditionalInfo: item.rewardAdditionalInfo,
        };
      })
      .sort((a, b) => {
        // Convert the transactionTime strings back to Date objects for comparison
        const dateA = new Date(a.transactionTime);
        const dateB = new Date(b.transactionTime);

        // Return 1 if dateA is less than dateB, -1 if dateA is greater than dateB, or 0 if they're equal
        return dateB - dateA;
      });

    return {rewardTransList: convertedRewardTransList, missingRewardTransList: missingRewardTransList};
  } catch (error) {
    console.log("Error calling API in fetchRewardData()", error);
    throw(error);
  } 
}

export const addMissingRewards = async function (missingRewardTransList, z_ticket_no, loginUserEmail) {
  //console.log("missingRewardTransList:", missingRewardTransList);
  try {
    const convertedMissingRewardTransList = missingRewardTransList
      .map((item) => {
        item["rewardAdditionalInfo"] = '#' + z_ticket_no + ':' 
          + new Date().toISOString().split('T')[0].replace(/-/g, '/') + ':' + loginUserEmail;
        return item;
      });

    //console.log("convertedMissingRewardTransList:", convertedMissingRewardTransList);

    const request = {
      meterVersion: 999,
      rewardsGetInfoAction: "ADD_REWARD_BY_PANID_DEVID_EMAILADDR",
      requestOriginator: "webapp",
      panelistId: convertedMissingRewardTransList[0].panelistId,
      deviceId: convertedMissingRewardTransList[0].deviceId,
      surveyRewardsTranactionsTblPtsList: convertedMissingRewardTransList 
    }

    const result = await callAmplifyAPI(request, "/rewards");

    //console.log("result:", result);

    checkResult(result);

    return result;

  } catch (error) {
    console.log("Error calling API in addMissingRewards()", error);
    throw error;
  }
};

export async function fetchRedeemData(panelistId) {
  try {
    const request = {
        meterVersion: 999,
        redeemTransactionType: "REDEEM_TRANSACTION_TYPE_GET_REDEEM_TRANS_BY_PANELIST_ID",
        //requestOriginator: "webapp",
        redeemRequestFields: {
          panelistId: panelistId,
          //requestTime: 1663345366000,      
        }
      }
    const result = await callAmplifyAPI(request, "/redeem");

    checkResult(result);

        // Get the reward transactions
    var redeemTransList = result.surveyRewardTransMeterQueryDataPts.redeemTransList;

    // Convert transactionTime for each element in the array
    var convertedRewardTransList = redeemTransList
      .map((item) => {
        return {
          email: item.email,
          panelistId: item.panelistId,
          deviceId: item.deviceId,
          transactionTime: convertTimestamp(item.transactionTime),
          transactionType: item.transactionType,
          currencyType: item.currencyType,
          rewardAmount: item.rewardAmount,
          rewardReason: item.rewardReason,
          rewardAdditionalInfo: item.rewardAdditionalInfo,
        };
      })
      .sort((a, b) => {
        // Convert the transactionTime strings back to Date objects for comparison
        const dateA = new Date(a.transactionTime);
        const dateB = new Date(b.transactionTime);

        // Return 1 if dateA is less than dateB, -1 if dateA is greater than dateB, or 0 if they're equal
        return dateB - dateA;
      });

    return convertedRewardTransList;
  } catch (error) {
    console.log("Error calling API in fetchRedeemData()", error);
    throw(error);
  } 
}
