<template>
<!-- Navigatin Bar -->
<nav class="navbar">
  <div class="logo">
    <img src="https://gwsolutions.com//wp-content//uploads//2018//10//GWS_Logobug-400.png" alt="GWS Inc." v-on:click="versionDialogVisible = true">
  </div>
  <div>
    <el-dialog v-model="versionDialogVisible" title="Version Information" @close="versionDialogVisible = false" width="30%">
      <div style="text-align: left;">
        <p>{{ versionString }}</p>
      </div>
      <template v-slot:footer>
        <el-button type="primary" @click="versionDialogVisible = false">Close</el-button>
      </template>
    </el-dialog>
  </div>
  <div v-if="user && user.attributes && user.attributes.email">
    <el-dropdown @command="loginUserMenuHandler">
      <span class="el-dropdown-link">
        {{ user.attributes.email }} 
        <el-icon><ArrowDown/></el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="signOut">Sign Out</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  </nav>

  <div>
    <div v-if="user && user.attributes && user.attributes.email">
      <HomeView :loginUserEmail="user.attributes.email"/>
    </div>
    <div v-else style="margin-top: 60px;">
      <authenticator :hideSignUp=true>
      </authenticator>
    </div>
  </div>
</template>

<style>
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    background-color: #f9fafc;
    height: 30px;
  }

  .logo img {
    height: 30px;
    width: auto;
  }

  .login-user {
    font-family: Arial, Helvetica, sans-serif; /* Replace with the font you want */
    font-size: 16px; /* Replace with the size you want */
    color: #333; /* Replace with the color you want */
  }
</style>

<script>
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

import { ref, toRefs } from 'vue'
import { Auth } from 'aws-amplify'

import { Amplify } from 'aws-amplify';
import awsconfig from '@/lib/aws-config';

import { ArrowDown } from '@element-plus/icons-vue'

import HomeView from '@/views/HomeView.vue'

export default {
  setup() {
    const versionDialogVisible = ref(false);
    const versionString = ref('Version 1.0.0');

    Amplify.configure(awsconfig.uk);

    const {user} = toRefs(useAuthenticator());

    const loginUserMenuHandler = (command) => {
      if (command === "signOut") {
        signOut();
      }
    };

    const signOut = async () => {
      try {
        await Auth.signOut()
        user.value = null
      } catch (error) {
        console.log('Error signing out', error)
      }
    }

    return {
      loginUserMenuHandler,
      user,
      signOut,
      versionDialogVisible,
      versionString
    }

  },
  components: {
    Authenticator,
    useAuthenticator,
    HomeView,
    ArrowDown
  }
}
</script>
